import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Row, Typography, Modal, Input, List, Tooltip, Spin } from 'antd';
import {
  PlayCircleOutlined,
  SendOutlined,
  AudioOutlined,
  SoundOutlined,
} from '@ant-design/icons';
import AIAsistan from '../../assets/dashboard/ai-assistant.svg';
import ChatService from '../../services/ChatService';

// ChatService.askText({ question }), ChatService.speakText({ text })
// ikisi de JSON gövdesi (Content-Type: application/json) kullanır

const { Title } = Typography;

/** Mesaj model */
interface Message {
  question: string;               // Kullanıcının sorduğu
  fullAnswer: string;             // ChatGPT'den gelen tam cevap
  displayedAnswer: string;        // Ekranda harf harf yazılan kısım
  isTyping: boolean;              // Yazma animasyonu devam ediyor mu?
  audioUrl: string | null;        // TTS MP3 blob URL
  isAudioPlaying: boolean;        // Şu an çalıyor mu?
  timestamp: string;              // Basit kimlik
  audioElement?: HTMLAudioElement; // Mesaja özel Audio nesnesi
}

/** Tarih-saat formatlayıcı */
const formatDateTime = () => {
  const now = new Date();
  return now.toLocaleString('tr-TR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
};

const AIAsistantCard: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState('');
  
  // Hem /ask hem /speak istekleri bitene kadar loading göstermek
  const [isLoading, setIsLoading] = useState(false);

  // Mikrofon
  const [isListening, setIsListening] = useState(false);
  const micRecognitionRef = useRef<any|null>(null);

  const messagesEndRef = useRef<HTMLDivElement>(null);

  /** Modal aç */
  const openModal = () => setIsModalOpen(true);
  /** Modal kapa */
  const closeModal = () => {
    setIsModalOpen(false);
    // İsterseniz tüm çalan sesleri durdurabilirsiniz
    messages.forEach(stopAudio);
  };

  /**
   * Soru Gönder -> ChatGPT metin -> TTS MP3
   * İki API de bitene kadar isLoading = true
   * Ardından typing effect ve otomatik ses çalma
   */
  const handleSend = async () => {
    if (!input.trim() || isLoading) return;

    setIsLoading(true);

    const newMsg: Message = {
      question: input,
      fullAnswer: '',
      displayedAnswer: '',
      isTyping: false,
      audioUrl: null,
      isAudioPlaying: false,
      timestamp: formatDateTime(),
    };

    setMessages((prev) => [...prev, newMsg]);
    const localQuestion = input;
    setInput('');

    try {
      // 1) /chat/ask: ChatGPT cevabı (text)
      const askResp = await ChatService.askText({ question: localQuestion });
      const answerText: string = askResp.data.answer;

      // 2) /chat/speak: TTS (MP3)
      const speakResp = await ChatService.speakText({ text: answerText });
      const arrayBuffer = speakResp.data;
      const blob = new Blob([arrayBuffer], { type: 'audio/mpeg' });
      const mp3Url = URL.createObjectURL(blob);

      // Mesajı güncelle: Cevap ve MP3 yüklenmiş durumda
      setMessages((prev) =>
        prev.map((m) =>
          m.timestamp === newMsg.timestamp && m.question === newMsg.question
            ? { ...m, fullAnswer: answerText, audioUrl: mp3Url }
            : m
        )
      );

      // 3) İkisi de hazır => typing + otomatik çalma
      startTypingEffect(newMsg.timestamp, newMsg.question, answerText);
      playAudio(newMsg.timestamp, newMsg.question, mp3Url);

    } catch (err) {
      console.error('Hata:', err);
    } finally {
      // İstekler bitti
      setIsLoading(false);
      scrollToBottom();
    }
  };

  /**
   * Harf harf yazma animasyonu
   */
  const startTypingEffect = (timestamp: string, question: string, text: string) => {
    let currentIndex = 0;
    const typingSpeed = 50; // ms

    // Mesajı "typing" moduna al
    setMessages((prev) =>
      prev.map((m) =>
        m.timestamp === timestamp && m.question === question
          ? { ...m, displayedAnswer: '', isTyping: true }
          : m
      )
    );

    const typeChar = () => {
      currentIndex++;
      const partial = text.substring(0, currentIndex);

      setMessages((prev) =>
        prev.map((m) =>
          m.timestamp === timestamp && m.question === question
            ? { ...m, displayedAnswer: partial }
            : m
        )
      );

      scrollToBottom();

      if (currentIndex < text.length) {
        setTimeout(typeChar, typingSpeed);
      } else {
        // Bitti
        setMessages((prev) =>
          prev.map((m) =>
            m.timestamp === timestamp && m.question === question
              ? { ...m, displayedAnswer: text, isTyping: false }
              : m
          )
        );
      }
    };

    typeChar();
  };

  /**
   * MP3 oynatma (otomatik veya hoparlöre tıklayınca)
   * Her mesaj için eğer yoksa bir Audio element oluşturur, varsa onu kullanır.
   * Eskiden "stopAudio" ile diğerlerini kapatıyorduk. 
   */
  const playAudio = (timestamp: string, question: string, mp3Url: string) => {
    setMessages((prev) =>
      prev.map((m) => {
        if (m.timestamp === timestamp && m.question === question) {
          // Halihazırda audioElement yoksa oluştur
          if (!m.audioElement) {
            const audioElem = new Audio(mp3Url);
            audioElem.onplay = () => {
              setMessages((pp) =>
                pp.map((mm) =>
                  mm.timestamp === timestamp && mm.question === question
                    ? { ...mm, isAudioPlaying: true }
                    : mm
                )
              );
            };
            audioElem.onended = () => {
              setMessages((pp) =>
                pp.map((mm) =>
                  mm.timestamp === timestamp && mm.question === question
                    ? { ...mm, isAudioPlaying: false }
                    : mm
                )
              );
            };
            // Kaydet
            m.audioElement = audioElem;
          } else {
            // Re-update src (örneğin minik bir caching vs. isteniyorsa)
            m.audioElement.src = mp3Url;
          }

          // Başa sar ve oynat
          m.audioElement.currentTime = 0;
          m.audioElement.play().catch((e) => console.error('Play error:', e));

          // State’e de kaydet
          return { ...m, audioUrl: mp3Url };
        }
        return m;
      })
    );
  };

  /**
   * Hoparlör butonuna basınca => "Aynı" audio element üzerinden toggle
   */
  const handleTogglePlay = (msg: Message) => {
    if (!msg.audioUrl) return;

    setMessages((prev) =>
      prev.map((m:any) => {
        if (m.timestamp === msg.timestamp && m.question === msg.question) {
          if (!m.audioElement) {
            // Henüz oluşturulmamışsa ilk kez
            const audioElem = new Audio(m.audioUrl);
            audioElem.onplay = () => {
              setMessages((pp) =>
                pp.map((mm) =>
                  mm.timestamp === msg.timestamp && mm.question === msg.question
                    ? { ...mm, isAudioPlaying: true }
                    : mm
                )
              );
            };
            audioElem.onended = () => {
              setMessages((pp) =>
                pp.map((mm) =>
                  mm.timestamp === msg.timestamp && mm.question === msg.question
                    ? { ...mm, isAudioPlaying: false }
                    : mm
                )
              );
            };
            audioElem.currentTime = 0;
            audioElem.play().catch((e) => console.error('Play error:', e));
            return { ...m, audioElement: audioElem };
          } else {
            // Toggle
            if (m.audioElement.paused) {
              // Başlat
              m.audioElement.currentTime = 0;
              m.audioElement.play().catch((e:any) => console.error('Play error:', e));
            } else {
              // Dur
              m.audioElement.pause();
              m.audioElement.currentTime = 0;
              return { ...m, isAudioPlaying: false };
            }
          }
        }
        return m;
      })
    );
  };

  /**
   * Bir mesajın sesini durdur
   */
  const stopAudio = (msg: Message) => {
    if (msg.audioElement && !msg.audioElement.paused) {
      msg.audioElement.pause();
      msg.audioElement.currentTime = 0;
      setMessages((prev) =>
        prev.map((m) =>
          m.timestamp === msg.timestamp && m.question === msg.question
            ? { ...m, isAudioPlaying: false }
            : m
        )
      );
    }
  };

  /**
   * Mikrofon
   */
  const initMic = () => {
    let r: any | null = null;
    const w = window as any;
    if ('webkitSpeechRecognition' in w) {
      r = new w.webkitSpeechRecognition();
    } else if ('SpeechRecognition' in w) {
      r = new w.SpeechRecognition();
    }
    if (r) {
      r.lang = 'tr-TR';
      r.continuous = true;
      r.interimResults = true;

      r.onresult = (evt: any) => {
        let finalTxt = '';
        let interimTxt = '';
        for (let i = evt.resultIndex; i < evt.results.length; i++) {
          const txtPart = evt.results[i][0].transcript;
          if (evt.results[i].isFinal) {
            finalTxt += txtPart;
          } else {
            interimTxt += txtPart;
          }
        }
        setInput((prev) => (prev + ' ' + finalTxt + ' ' + interimTxt).trim());
      };

      r.onend = () => {
        if (isListening) {
          r.start();
        }
      };

      r.onerror = (err: any) => {
        console.error('Mikrofon hata:', err.error);
      };
    }
    micRecognitionRef.current = r;
  };

  const handleListen = () => {
    if (!micRecognitionRef.current) {
      initMic();
    }
    const mic = micRecognitionRef.current;
    if (!mic) {
      console.warn('Bu tarayıcı mikrofon STT desteklemiyor.');
      return;
    }
    if (isListening) {
      mic.stop();
      setIsListening(false);
    } else {
      mic.start();
      setIsListening(true);
    }
  };

  /** Liste değişince en alta kay */
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Card className="ai-assistant-card" bordered={false}>
        <Title level={5}>AI Asistan</Title>
        <img className="ai-assistant-image" src={AIAsistan} alt="AI Asistan" />
        <Row justify="center">
          <Button
            type="primary"
            icon={<PlayCircleOutlined />}
            size="large"
            onClick={openModal}
          >
            Başla
          </Button>
        </Row>
      </Card>

      <Modal
        className="ai-assistant-card"
        title="AI Asistan"
        open={isModalOpen}
        onCancel={closeModal}
        footer={null}
        width={600}
      >
        {/* 
          Spin ile sarmalayarak isLoading=true olduğunda 
          içerik üzerine bir loading animasyonu koyduk 
        */}
        <Spin spinning={isLoading}>
          <div style={{ display: 'flex', flexDirection: 'column', height: 500, overflowY: 'auto' }}>
            <List
              dataSource={messages}
              renderItem={(item) => (
                <div key={item.timestamp} style={{ marginBottom: 12 }}>
                  {/* Kullanıcının sorusu */}
                  <List.Item className="chat-question-container">
                    <Tooltip title={item.timestamp}>
                      <div className="chat-question">{item.question}</div>
                    </Tooltip>
                  </List.Item>

                  {/* Cevabı harf harf yazıyoruz */}
                  <List.Item className="chat-answer-container">
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                      <div style={{ flex: 1, paddingRight: 8 }}>
                        {item.displayedAnswer
                          ? item.displayedAnswer
                          : (item.isTyping ? '...' : '')}
                      </div>
                      {/* Hoparlör (MP3 varsa) */}
                      {item.audioUrl && (
                        <SoundOutlined
                          style={{
                            fontSize: 20,
                            cursor: 'pointer',
                            color: item.isAudioPlaying ? 'red' : 'inherit',
                          }}
                          onClick={() => handleTogglePlay(item)}
                        />
                      )}
                    </div>
                  </List.Item>
                </div>
              )}
            />
            <div ref={messagesEndRef} />
          </div>
        </Spin>

        {/* Input + Mikrofon + Gönder */}
        <Input.Group compact>
          <Input
            style={{ width: 'calc(100% - 80px)' }}
            placeholder="Sorunuzu yazın veya konuşun..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onPressEnter={handleSend}
            disabled={isLoading} 
          />
          <Button
            icon={<AudioOutlined />}
            onClick={handleListen}
            style={{
              backgroundColor: isListening ? 'red' : undefined,
              color: isListening ? 'white' : undefined,
              transform: isListening ? 'scale(1.1)' : undefined,
              transition: 'transform 0.2s',
            }}
          />
          <Button
            type="primary"
            icon={<SendOutlined />}
            onClick={handleSend}
            disabled={isLoading || !input.trim()} 
          />
        </Input.Group>
      </Modal>
    </>
  );
};

export default AIAsistantCard;