// /services/ChatService.ts
import { http } from '../http-common';


const create = (data: any) => {
  return http.post<any>(`/chat/ask`, data);
};

const askText = (data: any) => {
  return http.post<any>(`/chat/ask`, data);
};

const speakText = (data: any) => {
  return http.post(`/chat/speak`, data, {
    responseType: 'arraybuffer',
  });
};

const ChatService = {
  create,
  askText,
  speakText
};

export default ChatService;